import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import About from "../components/about"
import Registry from "../components/registry"
import Contact from "../components/contact"
import Footer from "../components/footer";
import { Divider } from "theme-ui";
import Divider2 from "../components/divider";
import SEO from "../components/seo";
import {
  dividerFlip
} from '../components/layout.module.css'
const MinimalPortfolio = () => (
    <>
        <Banner/>
        <Layout>
            <SEO title="Mica Roe Turns One" />
            <About/>
            <Divider2 />
            <Contact/>
            <Divider2 className={`divider-flip`}/>
            <Registry/>
            <Footer/>
        </Layout>
    </>
)

export default MinimalPortfolio;