import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><br /><i>While a bird flying high,
from far off in Katroo,
will sing on the wing, 
      <br />"Happy Birthday to you!"</i>
    <p>{`Join us as we celebrate Mica Roe’s 1st birthday by singing a happy birthday song from the comforts of your own homes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      