import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><a href="https://us02web.zoom.us/j/82974937541?pwd=cFhTZGxDWEp6ZkVOYVhLNXpTeXNxZz09" target="_blank">Join Zoom Meeting</a><br />
Passcode: micaturns1
    <p>{`Don't forget to save the wallpaper as your Zoom background. You may click the photo to open in a new tab.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      