import React from "react"
import { Container, Image, Box, NavLink } from 'theme-ui'
import { StaticImage } from "gatsby-plugin-image"
import banner from '../images/micacloudsbg.jpg'
import coverimg from '../images/micacoverv3.jpg'
import titleimg from '../images/mica_title_v2.svg'
import balloonimg from '../images/micaballoonv3.png'
import BannerMDX from "../sections/banner"
import "@fontsource/coming-soon"
import {
  mainBanner,
  mainTitle,
  coverImage,
  left,
  right,
  bannerTitle,
  demo,
  navLinks,
  balloonImage,
  countdown
} from './layout.module.css'
var countDownDate = new Date("Sep 29, 2021 11:00:00").getTime();
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  // Output the result in an element with id="demo"
  // document.getElementById("demo").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  
}, 1000);
const Banner = () => (
    <Container
        bg='black'
        sx={{
            textAlign: 'center',
            background: 'url("'+String(banner)+'")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}
        className={mainBanner}
    >
    <Box className={left}>
        <StaticImage src="../images/micacoverv3.jpg" loading="eager" critical="true"
            className={coverImage}
        />
        <Image src={balloonimg} className={balloonImage}/>
    </Box>
    <Box className={right}>
        <Image src={titleimg}
            sx={{
                maxWidth: '100%',
                minWidth: '200px'
            }}
            className={mainTitle}
        />
        <Box sx={{
            color: "white",
            width: "100%",
            textAlign: "center"
        }} className={bannerTitle}>
            <BannerMDX /><br/>
            <span className={countdown}>Countdown</span>
            <Box 
            id="demo" className={demo}>
            00:00:00
            </Box>

        </Box>
        <Box as='nav'
            sx={{
                color: 'grey',
                fontSize: 'large',
                width: '100%'
            }}
            className={navLinks}
        >
            <NavLink href='#mica' p={2}>Party</NavLink>
            <NavLink href='#zoom' p={2}>Zoom</NavLink>
            <NavLink href='#registry' p={2}>Gift Registry</NavLink>
        </Box>
    </Box>
    </Container>
)

export default Banner;