import React from "react"
import RegistryMDX from "../sections/registry"
import { Grid, Box, Image } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image"
import GiftRegistryImage from '../images/giftregistry.svg'
import MyRegistry from '../images/myregistry.png'
import {
  section,
  giftRegistry,
  registryButton,
  registryImageBox
} from './layout.module.css'
const Registry = () => (
    <Grid
        pt={100}
        pb={100}
        pl={4}
        pr={4}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="registry"
        className={section}
    >
        <Box className={giftRegistry}>
            <Image src={GiftRegistryImage} />
            <RegistryMDX/>
            <a href="https://www.myregistry.com/giftlist/micaroeturns1?fbclid=IwAR2xJUay_TtKZzuHXvMhxOsdmZZFZMIm6cEq_zQ4d5dCuZS6dgWjw5N1o5o" target="_blank">
              <Image src={MyRegistry} className={registryButton} />
            </a>
        </Box>
        <Box sx={{
            textAlign: 'center'
        }} className={registryImageBox}>
            <StaticImage src='../images/partyimage.jpg'/>
        </Box>
    </Grid>
)

export default Registry;