import React from "react"
import { Container } from "theme-ui"
import {
  volcanoBackground
} from './layout.module.css'
const Layout = ({children}) => (
    <Container
        pl={[20, 20, 20, 20, 300]}
        pr={[20, 20, 20, 20, 300]}
        className={volcanoBackground}
    >
        {children}
    </Container>
)

export default Layout;