import React from "react"
import { Link, Text } from 'theme-ui'
import {
  footer
} from './layout.module.css'
const Footer = () => (
    <Text
        p={20}
        sx={{
            fontSize: 'small',
            textAlign: 'center',
        }}
        className={footer}
    >
        MICA ROE
        &copy;{new Date().getFullYear()}
    </Text>
)

export default Footer;