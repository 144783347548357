import React from "react"
import AboutMDX from "../sections/about"
import { Grid, Box, Image } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image"
import MicaTurnsOne from '../images/micaturnsone.svg'

import {
  section,
  partyImageBox
} from './layout.module.css'
const About = () => (
    <Grid
        pl={0}
        pr={0}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="mica"
        className={section}
    >
        <Box>
            <Image src={MicaTurnsOne}/>
            <AboutMDX/>
        </Box>
        <Box sx={{
            textAlign: 'center'
        }} className={partyImageBox}>
            <StaticImage src='../images/coverimage.jpg' />
        </Box>
    </Grid>
)

export default About;